import { defineStore } from 'pinia'
import type { Api } from '~~/global'

export const useFallbackStore = defineStore('FallbackStore', () => {
    const storesListFallback = ref() as Ref<Api.Responses.Pages.Stores>
    const categoriesListFallback = ref() as Ref<Api.Responses.Pages.Categories>
    const userProfileFallback = ref() as Ref<Api.Responses.Pages.UserProfile>
    const searchFallback = ref() as Ref<Api.Responses.Pages.SearchResults>

    const setStoresFallback = (data: Api.Responses.Pages.Stores) => {
        storesListFallback.value = data
    }

    const setSearchFallback = (data: Api.Responses.Pages.SearchResults) => {
        searchFallback.value = data
    }

    const setCategoriesFallback = (data: Api.Responses.Pages.Categories) => {
        categoriesListFallback.value = data
    }
    const setUserProfileFallback = (data: Api.Responses.Pages.UserProfile) => {
        userProfileFallback.value = data
    }

    return {
        storesListFallback,
        categoriesListFallback,
        userProfileFallback,
        searchFallback,
        setSearchFallback,
        setUserProfileFallback,
        setStoresFallback,
        setCategoriesFallback,
    }
})
